<template>
  <div>
    <CRow
      class="d-flex justify-content-between p-2 dark-block card-header"
      :class="getIsParentUser ? 'height-220' : 'header-height'"
    >
      <CCol col="4" class="position-relative">
        <img
          :src="generateFullUrlWithToken(item.profile_picture)"
          class="rounded-circle"
          style="width: 100%"
          onerror="this.onerror=null;this.onload=null;this.src='/img/user_default_pic.png';"
        />
        <center>
          <span v-if="item.gender"
            ><b>{{ item.gender }}</b></span
          ><br />
          <span v-if="item.age">{{ item.age }} Years</span>
        </center>
        <div
          v-if="isPrivate"
          class="position-absolute"
          style="top: 5px; left: 0px"
        >
          <i
            class="mx-2 fs-14 fa-solid fa-lock text-primary"
            v-c-tooltip="{
              content: 'Private Profile',
              appendToBody: true,
            }"
          ></i>
        </div>
      </CCol>
      <CCol
        col="8"
        class="pr-1 pl-0 position-relative mx-auto"
        style="word-break: break-all"
      >
        <div class="header-content p-0 m-0">
          <h6 class="m-0" :style="`color: ${getGenderColor(item.gender)}`">
            {{ toTitleCase(item.first_name) }} {{ toTitleCase(item.surname) }}
            <i  v-c-tooltip="{
              content: `Expired`
            }"  v-if="item?.current_score?.expired === true" class="fa fa-info-circle mr-1" aria-hidden="true"></i>
            <!-- Rediness Indicator -->
            <i v-if="eligibleIndicator" 
              class="fa-solid fa-certificate"
              :style="{ color: eligibleIndicator?.code}"
              style="font-size: 12px;"
              v-c-tooltip="{
                content: eligibleIndicator?.label}"></i>
          </h6>
          <div>
            <span class="small-header text-primary">
              {{ item.candidate_type }}
              <span v-if="item.main_qualification"
                >, {{ item.qualification }}({{
                  item.main_qualification_year
                }})</span
              >
              <span v-if="isStudent" class="in-training">in training</span>
            </span>
          </div>
          <div>
            <template v-if="isTFWTalentPool">
              <span class="small-text" v-if="item.nationality">{{
                item.nationality
              }}</span>
              <span class="small-text mx-1">|</span>
              <span class="small-text">
                ID :
                <span class="font-weight-bold"
                  >{{ item.customer_uid }}--{{ item.display_uid }}</span
                >
              </span>
            </template>
            <template v-else>
              <span class="small-text">
                ID :
                <span class="font-weight-bold">{{ item.display_uid }}</span>
              </span>
            </template>
          </div>

          <div
            class="item-info"
            v-if="
              currentUserRole !== Role.systemCandidate &&
              currentUserRole !== Role.customerCandidate
            "
          >
            <span class="info text-primary" style="font-size: 9px">Reg: {{ registrationDate }} </span>
            <span style="color: black; font-size: 9px"> | </span>
            <span class="info text-primary" style="font-size: 9px">Last Login: {{ lastLogin }} </span>
          </div>
          <div class="item-info d-flex align-items-baseline">
            <i class="far fa-envelope"></i>
            <span class="info">
              <span class="d-block" v-if="isUKMainAgency && isAgencyBadge">
                {{ item.agency_main_contact_email }}</span
              >
              <span class="d-block">{{
                isJobSupplier
                  ? item.email.replace(/.(?=[^@]*?.{0}@)/g, "X")
                  : item.email
              }}</span>
            </span>
          </div>
          <div class="item-info d-flex align-items-baseline">
            <i class="fa fa-phone"></i>
            <span class="info">
              <span class="d-block" v-if="isUKMainAgency && isAgencyBadge">
                {{ item.agency_main_contact_phone_no.replace("^", "-") }}</span
              >
              <span class="d-block">{{
                isJobSupplier
                  ? item.phone.replace("^", "-").replace(/\d(?=\d{3})/g, "X")
                  : item.phone.replace("^", "-")
              }}</span></span
            >
          </div>
          <div class="item-info">
            <i class="fab fa-whatsapp"></i>
            <span class="info">{{
              isJobSupplier && item.whatsapp
                ? item.whatsapp.replace("^", "-").replace(/\d(?=\d{3})/g, "X")
                : item.whatsapp
                ? item.phone.replace("^", "-")
                : "--"
            }}</span>
          </div>
          <div>
            <CBadge v-if="isAgencyBadge" color="danger" class="p-1">{{
              `Agency - ${item.organisation_name}`
            }}</CBadge>
            <CBadge
              v-if="isOrgBadge && !isAgencyBadge"
              color="danger"
              class="p-1"
              >{{ item.organisation_name }}</CBadge
            >
            <span
              class="three-dots mx-1"
              v-if="isOrgBadge && !isAgencyBadge && !isUKMainAgency && orgList"
              v-c-tooltip="{
                content: orgList,
              }"
            >
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </span>
          </div>
        </div>
        <div class="position-absolute" style="bottom: 10px; right: 22px">
          <CRow>
            <h5><span class="item-info" item.location></span></h5>
            <span
              class="step bg-secondary"
              v-if="item.retired_clinician === 'Yes'"
              v-c-tooltip="'Retired'"
              >R</span
            >
            <span
              class="step bg-secondary"
              v-if="item.volunteer === 'Yes'"
              v-c-tooltip="'Volunteer'"
              >V</span
            >
          </CRow>
        </div>
        <div
          class="position-absolute"
          style="top: 5px; right: 5px"
          v-if="getCustomerId"
        >
          <CDropdown color="primary" class="mx-2 w-100" :caret="false">
            <template #toggler>
              <span class="text-primary cursor-pointer">
                <i class="fa-solid fa-circle-plus fs-14"></i>
              </span>
            </template>
            <CDropdownItem
              v-show="!isTFWTalentPool"
              href="javascript:void(0)"
              @click="openShortlist(item)"
              >Shortlist</CDropdownItem
            >
            <CDropdownItem href="javascript:void(0)" @click="addToScratch"
              >Add to Scratchpad</CDropdownItem
            >
          </CDropdown>
        </div>
        <span>
          <CBadge v-if="item.is_student" color="matched" class="mr-2">
            Student
          </CBadge>
        </span>
        <span v-if="item.is_sponsored">
          <CBadge class="badge bg-primary text-white mr-2"> Sponsored </CBadge>
        </span>
        <span v-if="isTFWTalentPool">
          <CBadge
            v-if="item.customer_type_id == CONST.CUSTOMER_TYPES['agency']"
            color="primary"
          >
            Agency
          </CBadge>
        </span>
        <span>
          <CBadge
            v-if="
              item.customer_type_id ==
              CONST.CUSTOMER_TYPES['educational_institute']
            "
            style="background-color: #211C84; color: white"
          >
            Education
          </CBadge>
        </span>
      </CCol>
    </CRow>
    <ScratchPadModal
      v-if="scratchpad.isShowPopup"
      :todo="scratchpad.todo"
      :isShowPopup="scratchpad.isShowPopup"
      @modalCallBack="scratchPadModalCallBack"
    />
    <ShortListModal
      v-if="shortListModal.isShowPopup"
      :isShowPopup="shortListModal.isShowPopup"
      :candidateDetail="shortListModal.candidateDetail"
      @modalCallBack="shortListModalCallBack"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import isJobSupplier from "@/mixins/isJobSupplier";
import isCandidateSupplier from "@/mixins/isCandidateSupplier";
import ScratchPadModal from "@/containers/Dashboard/RecruiterDashBoard/ScratchPadModal";
import ShortListModal from "@/components/reusable/ShortList/ShortListModal";
import { CONSTANT as CONST, generateFullUrlWithToken } from "@/helpers/helper";

export default {
  name: "CandidateInfo",
  mixins: [isJobSupplier, isCandidateSupplier],
  props: {
    item: {
      type: Object,
      default: {},
    },
    index: {
      type: Number,
    },
  },
  components: {
    ScratchPadModal,
    ShortListModal,
  },
  data() {
    return {
      CONST,
      currentUserRole: getScope(),
      Role,
      scratchpad: {
        isShowPopup: false,
        todo: {},
      },
      shortListModal: {
        isShowPopup: false,
        candidateDetail: null,
      },
      generateFullUrlWithToken,
    };
  },
  computed: {
    ...mapGetters([
      "getStatusIcon",
      "getStatusColor",
      "getGenderColor",
      "getGenderImage",
      "isUKMainAgency", // pertemps,
      "getOrgIDFromAccessToken",
      "getIsParentUser",
      "isCandidateSupplierFromAccessToken",
      "getCustomerId",
      "getUserId",
      "getCustomerTypeIDFromAccessToken",
    ]),
    isStudent() {
      return this.item.is_student || false;
    },
    isPrivate() {
      return this.item.is_private || false;
    },
    isSponsored() {
      return this.item.is_sponsored || false;
    },
    isAgencyBadge() {
      return (
        (this.item.agency_contact_id &&
          this.item.organisation_name &&
          this.isUKMainAgency &&
          this.currentUserRole !== this.Role.systemAdmin) ||
        false
      );
    },
    isOrgBadge() {
      return (
        (this.item.organisation_id != this.getOrgIDFromAccessToken &&
          this.getIsParentUser) ||
        false
      );
    },
    isBadgeHave() {
      return this.isAgencyBadge || this.isOrgBadge || false;
    },
    lastLogin() {
      return this.item.last_date_of_login
        ? moment(this.item.last_date_of_login).format("DD-MM-YYYY")
        : "--";
    },
    registrationDate() {
      return this.item.created_on
        ? moment(this.item.created_on).format("DD-MM-YYYY")
        : "--";
    },
    orgList() {
      return this.item?.shared_organisations
        ?.map(({ name }) => name)
        .join(", ");
    },
    isTFWTalentPool() {
      const { params } = this.$route;
      return params.customer_type === "super_customer";
    },
    isEducationalInstitute() {
      return this.getCustomerTypeIDFromAccessToken === 5 || false;
    },
    eligibleIndicator() {
      const eligible = this.item?.readiness_eligibility ?? null;
      if(eligible !== null) {
        let newObject = {
          eligible: eligible?.eligible,
          eligible_6_12: eligible?.eligible_6_12,
          eligible_12_plus: eligible?.eligible_12_plus,
          eligible_incomplete: eligible?.eligible_incomplete,
        };
        Object.keys(newObject).forEach(key => {
          if (!newObject[key]) {
            delete newObject[key];
          }
        });

        const finalKey = Object.keys(newObject)
        const keyMappings = {
          eligible: { code: "#138808", label: "Eligible" },
          eligible_6_12: { code: "#FFBF00", label: "Eligible 6_12" },
          eligible_12_plus: { code: "#FF0000", label: "Eligible 12+" },
          eligible_incomplete: { code: "blue", label: "Not-Eligible" },
        };
        return  keyMappings[finalKey[0]] || null;
      };
    },
  },
  methods: {
    ...mapActions(["postScratchPad"]),
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    onImgError() {
      return "/img/user_default_pic.png";
    },
    addToScratch() {
      this.scratchpad.todo = {
        title: `Candidate - ${this.toTitleCase(
          this.item.first_name
        )} ${this.toTitleCase(this.item.surname)} (ID-${
          this.item.display_uid
        })`,
        description: `<p>Please review candidate <a href='/candidate/${
          this.item.candidate_uid
        }'>${this.toTitleCase(this.item.first_name)} ${this.toTitleCase(
          this.item.surname
        )} (ID-${this.item.display_uid})</a></p>`,
      };
      this.scratchpad.isShowPopup = true;
    },
    scratchPadModalCallBack(action, payload) {
      if (action && payload) {
        this.postScratchPad(payload);
      }
      this.scratchpad.isShowPopup = false;
      this.scratchpad.todo = null;
    },
    openShortlist(item) {
      this.shortListModal.isShowPopup = true;
      this.shortListModal.candidateDetail = item;
    },
    shortListModalCallBack(action, data) {
      this.shortListModal.isShowPopup = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.cust-progress {
  height: 8px !important;
}
.item-info {
  .info-small-text {
    display: flex;
    align-items: center;
    font-size: 12px;
    .info {
      margin-left: 5px;
    }
  }
}
.circle-border {
  border-radius: 50%;
  padding: 2px;
  margin: 2px;
}
span.step {
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: $white;
  display: inline-block;
  font-family: Poppins, sans-sarif !important;
  font-weight: 300;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em;
  opacity: 0.9;
}
.header-height {
  height: 200px;
}
// .candidate-card .agency-candidate {
//   background: grey;
//   border-radius: 10px 10px 0px 0px;
//   margin: 0px;
//   width: 100%;
//   min-height: 106px;
// }
.height-220 {
  height: 200px;
}
@media only screen and (max-width: 1516px) {
  .height-220 {
    height: 220px;
  }
}
.fs-14 {
  font-size: 14px !important;
}
.in-training{
  color: #5ecbf2
}
</style>
